.grid {
  display: flex;
}
.formCol {
  width: 500px;
}

.mapCol {
  flex: 1;
  padding: 30px 0 0 30px;
}
.mapShell {
  position: sticky;
  top: 10px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
}
