@import "vars.scss";


.banner {
    position: relative;
}
.banner__content {
    position: absolute;
    padding: 25px;
    
    z-index: 10;
    top: 0;
    bottom:0;

    background: linear-gradient(0deg, 
        rgba(255,255,255,0) 0%, 
        rgba(255,255,255,0.45) 70%,
        rgba(255,255,255,0.7) 100%);
    
}

.banner__title {
    font-size: 1.6em;
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.1em;

}

.banner--default {

    .banner__arrow {
        filter: brightness(0) invert(100%) sepia(0%) saturate(7477%) hue-rotate(277deg) brightness(97%) contrast(107%) drop-shadow(1px 1px 2px rgb(0 0 0 / 0.5));
    }
    .banner__rnb_id {    
        white-space: nowrap;
        text-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    }

    .banner__arrowtarget {
        position:absolute;
        width: 10px;
        height: 10px;
    }

    // Position elements depending of many screen sizes

    @media (min-width: $bp-xs) {
        .banner__arrowtarget {
            top: 207px;
            left: 318px;
        }
        .banner__arrow {
            top:50px;
            left: -94px;
            width: 140px;
            transform: rotate(-60deg);
        }
        .banner__rnb_id {
            top: 103px;
            left: -292px;
        }
    }

    @media (min-width: 480px) {
        .banner__arrowtarget {
            top: 229px;
            left: 348px;
        }
        .banner__image {
            width: 100%;
            height: auto;
        }
        .banner__arrowtarget {
            top: 246px;
            left: 394px;
        }
    }

    @media (min-width: $bp-sm) {
        .banner__arrowtarget {
            display: none;
        }
    }

    @media (min-width: 679px) {
        .banner__arrowtarget {
            display: block;
            top: 135px;
            left: 550px;
        }
        .banner__arrow {
            width: 147px;
            top: 30px;
            left: -126px;
            transform: rotate(-30deg);
        }

        .banner__rnb_id {
            top: 58px;
            left: -307px;
            font-size: 1.4em;
            text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
        }
    }
    @media (min-width: $bp-md) {
        .banner__arrowtarget {
            display: block;
            top: 147px;
            left: 663px;
        }
        .banner__arrow {
            width: 168px;
            top: 30px;
            left: -150px;
            transform: rotate(-30deg);
        }
        .banner__rnb_id {
            top: 65px;
            left: -329px;
            font-size: 1.4em;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        }
    }
    @media (min-width: 808px) {
        .banner__arrowtarget {
            top: 165px;
            left: 712px;
        }
    }
    @media (min-width: 873px) {
        .banner__arrowtarget {
            top: 181px;
            left: 748px;
        }
    }
    @media (min-width: $bp-lg) {
        .banner__arrowtarget {
            
            top: 138px;
            left: 502px;

            
        }
        .banner__arrow {
            width: 180px;
            top:30px;
            left:0px;
            transform: scale(-1, 1) rotate(-25deg);
        }

        .banner__rnb_id {
            top:53px;
            left:190px;
        }
    }
    @media (min-width: 1060px) {
        .banner__arrowtarget {
            top: 156px;
            left: 578px;
        }
    }
    @media (min-width: 1200px) {
        .banner__arrowtarget {
            top:185px;
            left:644px;
        }
    }

 
}



.banner__arrow {
    position: absolute;
    z-index: 10;
    
}

.banner__rnb_id {
    position: absolute;
    z-index: 10;

    font-weight: 900;
    background:none;
    
    font-family: 'Itim', cursive;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    color:white;
    font-size: 1.7em;
}

@media (min-width: $bp-sm) {
    .banner__content {

        background: linear-gradient(90deg, 
        rgba(255,255,255,0.70) 0%, 
        rgba(255,255,255,0.65) 70%,
        rgba(255,255,255,0) 100%);


    }
}

@media (min-width: $bp-md) {


    .banner__content {    

        display: flex;
        flex-direction: column;
        justify-content: center;
        
        
        
        top:0px;
        bottom: 0;
        left:0px;    
        max-width: 580px;
        padding-left:40px;
        padding-right:40px;
    }

    .banner__title {
        font-size: 2.7em;
    }

    
    .banner__rnb_id {
        
        
        
        font-size: 1.75em;
        transform: rotate(-1.5deg);
        
    }
    .banner__rnb_id:hover {
        color:#88fdaa;
    }

}






.banner__subtitle {
    font-size: 1.1rem;
    font-weight: 600;   
}












.sponsorGrid {
    align-items: center;
}

.sponsorBlock__logo {
    max-width: 100%;
    height: auto;
}
.sponsorBlock__logoAdeme {
    width:240px;
}

.bdgDefinition {

    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.04em;

    padding-left: 10px;
    border-left: 4px solid rgb(31,141,73,.7);

    @media (min-width: $bp-md) {
        font-size: 1.2em;
        padding-left: 25px;
        border-left: 6px solid rgb(31,141,73,.7);
    }
    
    
    
}
.bdgDefinitionContext {
    opacity: .75;
}

.home-search-bar-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    [class~="fr-search-bar"] {
        flex-basis: 100%;
    }

    // override addressAutocomplete styles
    .autocomplete_suggestions {
        border: 1px solid var(--blue-cumulus-925-125-hover);
        position: absolute;
        top: 39px;
        left: -2px;
        right: 0;
    }
}

.dbContainer {
    max-width: 90rem;
    margin:0 auto;
}

.searchSection {
    margin: 0 auto;
    max-width: 700px;
}

.dbsShell {
    background-image: linear-gradient(
        170deg,
        hsl(44deg 87% 88%) 0%,
        hsl(44deg 87% 87%) 12%,
        hsl(44deg 87% 86%) 23%,
        hsl(44deg 87% 85%) 34%,
        hsl(44deg 87% 85%) 45%,
        hsl(44deg 87% 84%) 55%,
        hsl(44deg 87% 83%) 65%,
        hsl(44deg 87% 82%) 75%,
        hsl(44deg 86% 81%) 85%,
        hsl(44deg 86% 80%) 100%
      );
    padding: 64px;

    @media (max-width: $bp-md) {
        padding: 12px;
    }
}

.searchContainer {
    margin-top: 2rem;
    background-color: white;
    padding: 24px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    --border-color:transparent;
    --border-size:1px;

    --shadow-color: 44deg 36% 61%;
    box-shadow:
    0.1px 0.5px 0.5px hsl(var(--shadow-color) / 0.39),
    0.4px 1.7px 1.9px -0.9px hsl(var(--shadow-color) / 0.37),
    1.1px 4.3px 4.8px -1.9px hsl(var(--shadow-color) / 0.34),
    2.7px 10.7px 11.8px -2.8px hsl(var(--shadow-color) / 0.32)
    ;

    border:var(--border-size) solid var(--border-color);

    @media (max-width: $bp-md) {
        padding: 12px;
    }
}

.searchContainer__title {
    font-weight: 600;
    margin-bottom: 6px;
    text-align: center;
}

.searchBar {
    width: 100%;
}

.featuredDatabases {
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;
    flex-direction: row;
    @media (max-width: $bp-md) {
        gap: 8px;
    }
}

.featuredDatabases__item {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.featuredDatabases__item__logo {
    width: 100%;
    height: auto;
}