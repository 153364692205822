.suggestion {
    padding: 4px 0px 4px 16px;
    z-index: 9900;
}

.selected {
    background: var(--blue-cumulus-925-125-hover);
}

.autocomplete_suggestions {
    background: white;
    cursor: pointer;
    padding: 6px 0 1px 0px;
    z-index: 101;
    border-top: none;
    flex-basis: 100%;
}